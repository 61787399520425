<template>
  <MitchOS :taskGroupBindings="['youTubePlayer']">
    <div class="col-left">
      <div class="col-left-inner">
        <div class="current-users">
          <h4>Current Users</h4>

          <ul
            v-if="clientsOnlineFiltered.length"
            class="list-unstyled"
          >
            <li
              v-for="item in clientsOnlineFiltered"
              :key="item.uid"
            >
              <img
                class="avatar"
                alt="Avatar"
                :src="item.profileImageURL"
                :title="JSON.stringify(item.stats)"
              />

              {{ item.username }}

              <small
                v-if="item.status === 'away'"
                class="text-muted"
              > (Away) </small>
            </li>
          </ul>
        </div>

        <div class="text-box has-scrollbars">
          <!-- HEADING -->
          <h1 class="text-center text-white">The Mitching App <small class="h6">v0.0.5</small></h1>

          <p class="lead mb-4">
            Request songs with Mitch-bucks™ using
            <code class="text-primary">!sr &lt;YouTube Video URL&gt;</code>
          </p>

          <!-- USERDATA -->
          <!-- <pre v-text="userData"></pre> -->

          <!-- YOUTUBE PLAYER -->
          <div v-if="youTubePlayer">
            <div class="youtube-window shadow-lg">
              <YouTubePlayer :options="{
                  controls: true,
                  showPlayerText: true,
                }" />
            </div>
          </div>
          <div
            v-else
            class="text-center py-4"
          >
            <h4><i class="fa fa-spinner fa-spin mr-1"></i> Nothing to see. Please stand by.</h4>
          </div>

          <button
            class="btn btn-logout btn-lg btn-danger"
            @click="logout()"
          >Logout</button>
        </div>
      </div>
    </div>
    <div class="col-right hidden-sm-down">
      <!-- TWITCH CHAT -->
      <!-- <iframe class="twitch-chat" frameborder="0" scrolling="no" :src="chatURL"> </iframe> -->
    </div>

    <!-- DEBUG -->
    <!-- <Debug /> -->
  </MitchOS>
</template>

<script>
  import { mapGetters } from "vuex";
  import Debug from "@/components/MitchOS/Programs/Debug-v1/Debug.vue";
  import MitchOS from "@/components/MitchOS/MitchOS.vue";
  import YouTubePlayer from "@/components/MitchOS/Programs/YouTubePlayer/YouTubePlayer.vue";

  const manifest = window.composeManifest(
    [
      // './MitchOS/Programs/',
      "./fonts/",
      // './MitchOS/Transitions/' // works
      // './MitchOS/Transitions/assets/audio/'  doesn't work
    ],
    { ignore: [".mp3"] }
  );

  export default {
    name: "UserPage",
    components: {
      Debug,
      MitchOS,
      YouTubePlayer,
    },
    props: {
      preloadComplete: {
        type: Boolean,
        required: false,
      },
    },
    computed: {
      ...mapGetters({
        clientsOnline: "user/getClientsOnline",
        getActiveTask: "tasks/getActiveTask",
        userData: "user/getUserData",
      }),
      clientsOnlineFiltered() {
        return (
          this.clientsOnline?.reduce((acc, item) => {
            // const key = Object.keys(item.devices)[0];
            const key = Object.keys(item.devices).filter(
              (device) => !item.devices[device].isLocked
            )[0];

            if (key) {
              acc.push({
                ...item.devices[key],
                uid: key, // for loop key
              });
            }
            return acc;
          }, []) || []
        );
      },
      youTubePlayer() {
        return !!this.getActiveTask("youTubePlayer", "Run");
      },
      chatURL() {
        const parentURL = window.location.hostname;
        return `https://www.twitch.tv/embed/themitchinghour/chat?parent=${parentURL}&darkpopout`;
      },
    },
    created() {
      /**
       * EMIT - REQUEST PRELOADER
       * Each view has its own set of assets to load
       */
      this.$emit("request-preloader", {
        manifest,
      });

      // Bind youtube playlists
      this.$store.dispatch("youtube/bindPlaylists");
    },
    methods: {
      logout() {
        this.$store.dispatch("user/logout");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mitch-os {
    // display: flex;
    // flex-direction: row;
    height: 100%;
    padding: rem-calc(20);
    background-color: #363840;
    // background: black url('~@/assets/img/blurred-bg-2@2x.jpg') bottom center no-repeat;
    background: black url("~@/assets/img/blurred-bg-3.jpg") bottom center no-repeat;
    background-size: cover;
    color: white;
  }

  .btn-logout {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .text-box {
    background: rgba(black, 0.2);
    padding: rem-calc(30 30 0 30);
    border-radius: 10px;
    height: rem-calc(620);
    overflow-y: auto;
  }

  .col-left {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: rem-calc(20);

    @include media-breakpoint-up(md) {
      width: calc(100% - #{rem-calc(340)});
      padding: rem-calc(30 100);
    }
  }

  .col-left-inner {
    margin: 0 auto;
    max-width: rem-calc(800);
  }

  .col-right {
    position: absolute;
    top: 0;
    right: 0;
    width: rem-calc(340);
    height: 100%;
    padding: rem-calc(20);
  }

  .youtube-window {
    // padding: rem-calc(12);
    // background: rgba(black, 0.3);
    // border-radius: 4px;
    // border: 1px solid rgba(black, 1);
    padding-bottom: rem-calc(30);
  }

  .twitch-chat {
    width: 100%;
    height: 70%;
  }

  code {
    background: rgba(black, 0.2);
    padding: rem-calc(3 4);
    border-radius: 3px;
  }

  .current-users {
    position: absolute;
    top: rem-calc(80);
    left: 0;
    width: rem-calc(230);
    padding: rem-calc(15);
    background: rgba(black, 0.2);

    .avatar {
      border-radius: 100%;
      width: rem-calc(20);
    }
  }
</style>
